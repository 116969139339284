import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/products_enum";
import {
  useUpdateProduct,
  useSingleProduct,
} from "../../shared/hooks/UseProduct";
// import { useSelectAllProduct } from "../../shared/hooks/UseProduct";
import { useSelectAllCategory } from "../../shared/hooks/UseCategory";
import { useSelectAllGenerator } from "../../shared/hooks/UseGenerator";
const EditProduct = ({ match }) => {
  let history = useHistory();
  const [single_data] = useSingleProduct(match.params.id);
  const [updateData] = useUpdateProduct();
  const { product_loading, product, edit_product_loading } = single_data;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  //  const [product1] = useSelectAllProduct();
  const [categories] = useSelectAllCategory();
  const [generators] = useSelectAllGenerator();
  const [dropdownOptions, setDropdownOptions] = useState(null);
  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await updateData(product._id, data);
    history.push(`/${LINK_URL}/${product._id}/view`);
  };

  // useEffect(() => {
  //   if (product1 && product1.all_products) {
  //     const newData = product1.all_products.map((item) => {
  //       return { label: item.product_name, value: item._id };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, parent_product: newData });
  //   }
  // }, [product1]);
  useEffect(() => {
    if (categories && categories.all_categorys) {
      const newData = categories.all_categorys.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, category: newData });
    }
  }, [categories]);
  useEffect(() => {
    if (generators && generators.all_generators) {
      const newData = generators.all_generators.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, generators: newData });
    }
  }, [generators]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!product_loading ? (
              product && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {product[Object.keys(inputFields)[0]]} -{" "}
                        <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${product._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={product}
                    edit={true}
                    featuredImage={featuredImage}
                    gallery={gallery}
                    setFeaturedImage={setFeaturedImage}
                    setGallery={setGallery}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                    loading={edit_product_loading}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
