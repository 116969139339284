export const PAGE_TITLE = "Banners";
export const PAGE_SINGLE_TITLE = "Banner";
export const LINK_URL = "banners";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "",
    options: "",
    field: "",
  },
  image: {
    type: "file",
    required: false,
    title: "Image",
    inputType: "",
    options: "",
    field: "",
  },
};

export const initialValues = {};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Image", value: "image", image: true },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
