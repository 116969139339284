export const GET_FOOTERS_STATED = "GET_FOOTERS_STATED";
export const GET_FOOTERS = "GET_FOOTERS";
export const GET_FOOTERS_ENDED = "GET_FOOTERS_ENDED";
export const ADD_FOOTER_STATED = "ADD_FOOTER_STARTED";
export const ADD_FOOTER = "ADD_FOOTER";
export const ADD_FOOTER_ENDED = "ADD_FOOTER_ENDED";
export const EDIT_FOOTER_STATED = "EDIT_FOOTER_STATED";
export const EDIT_FOOTER = "EDIT_FOOTER";
export const EDIT_FOOTER_ENDED = "EDIT_FOOTER_ENDED";
export const GET_FOOTER = "GET_FOOTER";
export const GET_FOOTER_STATED = "GET_FOOTER_STATED";
export const GET_FOOTER_ENDED = "GET_FOOTER_ENDED";
export const RESET_FOOTER = "RESET_FOOTER";
export const ERROR_FOOTER = "ERROR_FOOTER";
export const GET_ALL_FOOTERS_STATED = "GET_ALL_FOOTERS_STATED";
export const GET_ALL_FOOTERS = "GET_ALL_FOOTERS";
export const GET_ALL_FOOTERS_ENDED = "GET_ALL_FOOTERS_ENDED";
