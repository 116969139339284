export const MENU = [
  {
    label: "Dashboard",
    link: "/dashboard",
    role: ["SUPER_ADMIN", "VENDOR"],
  },

  {
    label: "Job Cards",
    link: "/jobcards",
    role: ["SUPER_ADMIN", "VENDOR"],
  },
  {
    label: "Products",
    link: "#",
    role: ["SUPER_ADMIN"],
    menu: [
      {
        label: "Generators",
        link: "/generators",
        role: ["SUPER_ADMIN"],
      },
      {
        label: "Categories",
        link: "/categories",
        role: ["SUPER_ADMIN"],
      },

      {
        label: "Products",
        link: "/products",
        role: ["SUPER_ADMIN"],
      },
      {
        label: "Services",
        link: "/services",
        role: ["SUPER_ADMIN"],
      },
    ],
  },

  {
    label: "Coupons",
    link: "/coupons",
    role: ["SUPER_ADMIN"],
  },
  {
    label: "Complaints",
    link: "/complaints",
    role: ["SUPER_ADMIN", "VENDOR"],
  },
  {
    label: "Contacts",
    link: "/contacts",
    role: ["SUPER_ADMIN"],
  },
  {
    label: "Orders",
    link: "/orders",
    role: ["SUPER_ADMIN", "VENDOR"],
  },

  {
    label: "Users",
    link: "#",
    role: ["SUPER_ADMIN", "VENDOR"],
    menu: [
      {
        label: "Vendors",
        link: "/vendors",
        role: ["SUPER_ADMIN"],
      },
      {
        label: "Customers",
        link: "/customers",
        role: ["SUPER_ADMIN", "VENDOR"],
      },
      {
        label: "Technicians",
        link: "/technicians",
        role: ["SUPER_ADMIN", "VENDOR"],
      },
    ],
  },
  {
    label: "Settings",
    role: ["SUPER_ADMIN"],
    link: "#",
    menu: [
      {
        label: "Offers",
        link: "/offers",
        role: ["SUPER_ADMIN"],
      },
      {
        label: "Banners",
        link: "/banners",
        role: ["SUPER_ADMIN"],
      },
      {
        label: "Footer Banners",
        link: "/footers",
        role: ["SUPER_ADMIN"],
      },
    ],
  },
];
