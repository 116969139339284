import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDashboard } from "../../store/actions/dashboard_action";

// Add Data
export const useGetDashboard = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getDashboard());
  }, []);
  return [data];
};
