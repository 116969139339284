import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/orders_enum";
import { useUpdateOrder, useSingleOrder } from "../../shared/hooks/UseOrder";
import { useSelectAllCustomer } from "../../shared/hooks/UseCustomer";
import { useSelectAllVendor } from "../../shared/hooks/UseVendor";
import { useSelectAllCoupon } from "../../shared/hooks/UseCoupon";
import { useSelectAllProduct } from "../../shared/hooks/UseProduct";
import { useSelectAllTechnician } from "../../shared/hooks/UseTechnician";

const EditOrder = ({ match }) => {
  let history = useHistory();
  const [single_data] = useSingleOrder(match.params.id);
  const [updateData] = useUpdateOrder();
  const { order_loading, order, edit_order_loading } = single_data;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  //  const [order1] = useSelectAllOrder();
  const [customers] = useSelectAllCustomer();
  const [vendors] = useSelectAllVendor();
  const [coupons] = useSelectAllCoupon();
  const [products] = useSelectAllProduct();
  const [technician] = useSelectAllTechnician();

  const [dropdownOptions, setDropdownOptions] = useState(null);
  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await updateData(order._id, data);
    history.push(`/${LINK_URL}/${order._id}/view`);
  };

  // useEffect(() => {
  //   if (order1 && order1.all_orders) {
  //     const newData = order1.all_orders.map((item) => {
  //       return { label: item.order_name, value: item._id };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, parent_order: newData });
  //   }
  // }, [order1]);
  useEffect(() => {
    if (customers && customers.all_customers) {
      const newData = customers.all_customers.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, customer: newData });
    }
  }, [customers]);
  useEffect(() => {
    if (vendors && vendors.all_vendors) {
      const newData = vendors.all_vendors.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, vendor: newData });
    }
  }, [vendors]);
  useEffect(() => {
    if (coupons && coupons.all_coupons) {
      const newData = coupons.all_coupons.map((item) => {
        return { label: item.code, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, coupon: newData });
    }
  }, [coupons]);
  useEffect(() => {
    if (products && products.all_products) {
      const newData = products.all_products.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, products: newData });
    }
  }, [products]);
  useEffect(() => {
    if (technician && technician.all_technicians) {
      const newData = technician.all_technicians.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, technician: newData });
    }
  }, [technician]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!order_loading ? (
              order && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${order._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={order}
                    edit={true}
                    featuredImage={featuredImage}
                    gallery={gallery}
                    setFeaturedImage={setFeaturedImage}
                    setGallery={setGallery}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                    loading={edit_order_loading}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrder;
