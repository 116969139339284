import api from "../../domain/api";
import {
  GET_COMPLAINTS_STATED,
  GET_COMPLAINTS,
  GET_COMPLAINTS_ENDED,
  ADD_COMPLAINT_STATED,
  ADD_COMPLAINT,
  ADD_COMPLAINT_ENDED,
  EDIT_COMPLAINT_STATED,
  EDIT_COMPLAINT,
  EDIT_COMPLAINT_ENDED,
  GET_COMPLAINT_STATED,
  GET_COMPLAINT,
  GET_COMPLAINT_ENDED,
  GET_ALL_COMPLAINTS_STATED,
  GET_ALL_COMPLAINTS,
  GET_ALL_COMPLAINTS_ENDED,
} from "../types/complaint_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addComplaint = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_COMPLAINT_STATED,
    });
    const { data } = await api.post(`/complaints`, formData);
    dispatch({
      type: ADD_COMPLAINT,
      payload: data,
    });
    dispatch({
      type: ADD_COMPLAINT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_COMPLAINT_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getComplaints =
  ({ pageNumber = "", start_from = "", start_to = "", queryParams }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_COMPLAINTS_STATED,
      });

      const { data } = await api.get(
        `/complaints?&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}${queryParams}`
      );

      dispatch({
        type: GET_COMPLAINTS,
        payload: data,
      });
      dispatch({
        type: GET_COMPLAINTS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPLAINTS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getComplaint = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMPLAINT_STATED,
    });
    const { data } = await api.get(`/complaints/${id}`);

    dispatch({
      type: GET_COMPLAINT,
      payload: data,
    });
    dispatch({
      type: GET_COMPLAINT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_COMPLAINT_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editComplaint = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_COMPLAINT_STATED,
    });
    const { data } = await api.put(`/complaints/${id}`, formData);
    dispatch({
      type: EDIT_COMPLAINT,
      payload: data,
    });
    dispatch({
      type: EDIT_COMPLAINT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_COMPLAINT_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteComplaint = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/complaints/${id}`);
    dispatch(setAlert("Complaint Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllComplaints = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_COMPLAINTS_STATED,
    });
    const { data } = await api.get(`/complaints/all`);

    dispatch({
      type: GET_ALL_COMPLAINTS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_COMPLAINTS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_COMPLAINTS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
