import api from "../../domain/api";
import {
  GET_FOOTERS_STATED,
  GET_FOOTERS,
  GET_FOOTERS_ENDED,
  ADD_FOOTER_STATED,
  ADD_FOOTER,
  ADD_FOOTER_ENDED,
  EDIT_FOOTER_STATED,
  EDIT_FOOTER,
  EDIT_FOOTER_ENDED,
  GET_FOOTER_STATED,
  GET_FOOTER,
  GET_FOOTER_ENDED,
  GET_ALL_FOOTERS_STATED,
  GET_ALL_FOOTERS,
  GET_ALL_FOOTERS_ENDED,
} from "../types/footer_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addFooter = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_FOOTER_STATED,
    });
    const { data } = await api.post(`/footers`, formData);
    dispatch({
      type: ADD_FOOTER,
      payload: data,
    });
    dispatch({
      type: ADD_FOOTER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_FOOTER_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getFooters =
  ({ pageNumber = "", start_from = "", start_to = "", queryParams }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_FOOTERS_STATED,
      });

      const { data } = await api.get(
        `/footers?&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}${queryParams}`
      );

      dispatch({
        type: GET_FOOTERS,
        payload: data,
      });
      dispatch({
        type: GET_FOOTERS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_FOOTERS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getFooter = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FOOTER_STATED,
    });
    const { data } = await api.get(`/footers/${id}`);

    dispatch({
      type: GET_FOOTER,
      payload: data,
    });
    dispatch({
      type: GET_FOOTER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_FOOTER_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editFooter = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_FOOTER_STATED,
    });
    const { data } = await api.put(`/footers/${id}`, formData);
    dispatch({
      type: EDIT_FOOTER,
      payload: data,
    });
    dispatch({
      type: EDIT_FOOTER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_FOOTER_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteFooter = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/footers/${id}`);
    dispatch(setAlert("Footer Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllFooters = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_FOOTERS_STATED,
    });
    const { data } = await api.get(`/footers/all`);

    dispatch({
      type: GET_ALL_FOOTERS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_FOOTERS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_FOOTERS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
