import {
  GET_JOBCARDS_STATED,
  GET_JOBCARDS,
  GET_JOBCARDS_ENDED,
  ADD_JOBCARD_STATED,
  ADD_JOBCARD,
  ADD_JOBCARD_ENDED,
  EDIT_JOBCARD_STATED,
  EDIT_JOBCARD,
  EDIT_JOBCARD_ENDED,
  GET_JOBCARD_STATED,
  GET_JOBCARD,
  GET_JOBCARD_ENDED,
  GET_ALL_JOBCARDS_STATED,
  GET_ALL_JOBCARDS,
  GET_ALL_JOBCARDS_ENDED
} from "../types/jobcard_type";

const initialState = {
  jobcards_loading: true,
  jobcards: null,
  page: null,
  pages: null,
  total_jobcards: 0,

  jobcard: null,
  jobcard_loading: null,

  loading: true,

  jobcard_message: null,
  all_jobcards: null,
  all_jobcards_loading: null,
  add_jobcard_loading: true,
  edit_jobcard_loading: true
};

export const jobcard_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_JOBCARDS_STATED:
      return {
        ...state,
        jobcards: null,
        pages: null,
        page: null,
        total_jobcards: 0,
        jobcards_loading: true
      };
    case GET_JOBCARDS:
      return {
        ...state,
        jobcards: payload.jobcards,
        pages: payload.pages,
        page: payload.page,
        total_jobcards: payload.count
      };
    case GET_JOBCARDS_ENDED:
      return {
        ...state,
        jobcards_loading: false
      };
    case GET_ALL_JOBCARDS_STATED:
      return {
        ...state,
        all_jobcards_loading: true,
        all_jobcards: null
      };
    case GET_ALL_JOBCARDS:
      return {
        ...state,
        all_jobcards: payload
      };
    case GET_ALL_JOBCARDS_ENDED:
      return {
        ...state,
        all_jobcards_loading: false
      };

    case ADD_JOBCARD_STATED:
      return {
        ...state,
        jobcard_message: null,
        add_jobcard_loading: true
      };
    case ADD_JOBCARD:
      return {
        ...state,
        jobcard_message: payload
      };
    case ADD_JOBCARD_ENDED:
      return {
        ...state,
        add_jobcard_loading: false
      };
    case GET_JOBCARD_STATED:
      return {
        ...state,
        jobcard: null,
        jobcard_loading: true
      };
    case GET_JOBCARD:
      return {
        ...state,
        jobcard: payload
      };
    case GET_JOBCARD_ENDED:
      return {
        ...state,
        jobcard_loading: false
      };
    case EDIT_JOBCARD_STATED:
      return {
        ...state,
        jobcard_message: null,
        edit_jobcard_loading: true
      };
    case EDIT_JOBCARD:
      return {
        ...state,
        jobcard_message: payload
      };
    case EDIT_JOBCARD_ENDED:
      return {
        ...state,
        edit_jobcard_loading: false
      };

    default:
      return state;
  }
};
