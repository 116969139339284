import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/technicians_enum";
import { useCreateTechnician } from "../../shared/hooks/UseTechnician";
import { useSelector } from "react-redux";
import { useSelectAllVendor } from "../../shared/hooks/UseVendor";

const AddTechnician = ({}) => {
  let history = useHistory();
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const [technician, addData] = useCreateTechnician();
  const { add_technician_loading } = technician;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [vendors] = useSelectAllVendor();

  const [dropdownOptions, setDropdownOptions] = useState({});

  const submitFormClicked = async (values) => {
    if (user && user.role == "VENDOR") {
      values.vendor = user._id;
    }
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/${LINK_URL}`);
  };
  const [newInputFields, setNewInputFields] = useState(inputFields);
  useEffect(() => {
    if (user && user.role == "VENDOR") {
      let checkInputFields = inputFields;
      delete checkInputFields.vendor;
      setNewInputFields(checkInputFields);
    }
  }, [user]);

  useEffect(() => {
    if (vendors && vendors.all_vendors) {
      const newData = vendors.all_vendors.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, vendor: newData });
    }
  }, [vendors]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            {user && (
              <AddForm
                edit={false}
                featuredImage={featuredImage}
                gallery={gallery}
                setFeaturedImage={setFeaturedImage}
                setGallery={setGallery}
                submitForm={submitFormClicked}
                inputFields={newInputFields}
                initialValues={initialValues}
                dropdown_options={dropdownOptions}
                loading={add_technician_loading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTechnician;
