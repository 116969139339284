import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addProduct,
  getProducts,
  getProduct,
  editProduct,
  deleteProduct,
  getAllProducts,
} from "../../store/actions/product_action";

// Get All Data
export const useAllProducts = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.product);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  useEffect(() => {
    async function allQuery() {
      const params = new URLSearchParams(window.location.search);

      const paramsObj = Array.from(params.keys()).reduce(
        (acc, val) => ({ ...acc, [val]: params.get(val) }),
        {}
      );
      let newQParams = ``;
      Object.keys(paramsObj) &&
        Object.keys(paramsObj).map((item) => {
          newQParams += `&search[${item}]=${paramsObj[item]}`;
        });
      if (deleteEntry) {
      await  dispatch(deleteProduct(deleteEntry));
      }
      await dispatch(
        getProducts({
          pageNumber,
          start_from: dateFrom,
          start_to: dateTo,
          queryParams: newQParams,
        })
      );
    }
    allQuery();
  }, [deleteEntry, pageNumber, dateFrom, dateTo, window.location.search]);
  useEffect(() => {
    setPageNumber(1);
  }, [dateTo, dateFrom]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [
    data,
    setPageNumber,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    deleteBtnClicked,
  ];
};

// Get Single Data
export const useSingleProduct = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.product);
  useEffect(() => {
    dispatch(getProduct(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateProduct = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.product);
  const addData = async (data) => {
    await dispatch(addProduct(data));
  };
  return [data, addData];
};
export const useUpdateProduct = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.product);
  const updateData = async (id, data) => {
    await dispatch(editProduct(id, data));
  };
  return [updateData];
};

export const useSelectAllProduct = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.product);
  useEffect(() => {
    dispatch(getAllProducts());
  }, []);
  return [data];
};
