export const GET_OFFERS_STATED = "GET_OFFERS_STATED";
export const GET_OFFERS = "GET_OFFERS";
export const GET_OFFERS_ENDED = "GET_OFFERS_ENDED";
export const ADD_OFFER_STATED = "ADD_OFFER_STARTED";
export const ADD_OFFER = "ADD_OFFER";
export const ADD_OFFER_ENDED = "ADD_OFFER_ENDED";
export const EDIT_OFFER_STATED = "EDIT_OFFER_STATED";
export const EDIT_OFFER = "EDIT_OFFER";
export const EDIT_OFFER_ENDED = "EDIT_OFFER_ENDED";
export const GET_OFFER = "GET_OFFER";
export const GET_OFFER_STATED = "GET_OFFER_STATED";
export const GET_OFFER_ENDED = "GET_OFFER_ENDED";
export const RESET_OFFER = "RESET_OFFER";
export const ERROR_OFFER = "ERROR_OFFER";
export const GET_ALL_OFFERS_STATED = "GET_ALL_OFFERS_STATED";
export const GET_ALL_OFFERS = "GET_ALL_OFFERS";
export const GET_ALL_OFFERS_ENDED = "GET_ALL_OFFERS_ENDED";
