export const GET_PRODUCTS_STATED = "GET_PRODUCTS_STATED";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_ENDED = "GET_PRODUCTS_ENDED";
export const ADD_PRODUCT_STATED = "ADD_PRODUCT_STARTED";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_ENDED = "ADD_PRODUCT_ENDED";
export const EDIT_PRODUCT_STATED = "EDIT_PRODUCT_STATED";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const EDIT_PRODUCT_ENDED = "EDIT_PRODUCT_ENDED";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_STATED = "GET_PRODUCT_STATED";
export const GET_PRODUCT_ENDED = "GET_PRODUCT_ENDED";
export const RESET_PRODUCT = "RESET_PRODUCT";
export const ERROR_PRODUCT = "ERROR_PRODUCT";
export const GET_ALL_PRODUCTS_STATED = "GET_ALL_PRODUCTS_STATED";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_ENDED = "GET_ALL_PRODUCTS_ENDED";
