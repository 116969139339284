import {
  GET_COMPLAINTS_STATED,
  GET_COMPLAINTS,
  GET_COMPLAINTS_ENDED,
  ADD_COMPLAINT_STATED,
  ADD_COMPLAINT,
  ADD_COMPLAINT_ENDED,
  EDIT_COMPLAINT_STATED,
  EDIT_COMPLAINT,
  EDIT_COMPLAINT_ENDED,
  GET_COMPLAINT_STATED,
  GET_COMPLAINT,
  GET_COMPLAINT_ENDED,
  GET_ALL_COMPLAINTS_STATED,
  GET_ALL_COMPLAINTS,
  GET_ALL_COMPLAINTS_ENDED
} from "../types/complaint_type";

const initialState = {
  complaints_loading: true,
  complaints: null,
  page: null,
  pages: null,
  total_complaints: 0,

  complaint: null,
  complaint_loading: null,

  loading: true,

  complaint_message: null,
  all_complaints: null,
  all_complaints_loading: null,
  add_complaint_loading: true,
  edit_complaint_loading: true
};

export const complaint_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_COMPLAINTS_STATED:
      return {
        ...state,
        complaints: null,
        pages: null,
        page: null,
        total_complaints: 0,
        complaints_loading: true
      };
    case GET_COMPLAINTS:
      return {
        ...state,
        complaints: payload.complaints,
        pages: payload.pages,
        page: payload.page,
        total_complaints: payload.count
      };
    case GET_COMPLAINTS_ENDED:
      return {
        ...state,
        complaints_loading: false
      };
    case GET_ALL_COMPLAINTS_STATED:
      return {
        ...state,
        all_complaints_loading: true,
        all_complaints: null
      };
    case GET_ALL_COMPLAINTS:
      return {
        ...state,
        all_complaints: payload
      };
    case GET_ALL_COMPLAINTS_ENDED:
      return {
        ...state,
        all_complaints_loading: false
      };

    case ADD_COMPLAINT_STATED:
      return {
        ...state,
        complaint_message: null,
        add_complaint_loading: true
      };
    case ADD_COMPLAINT:
      return {
        ...state,
        complaint_message: payload
      };
    case ADD_COMPLAINT_ENDED:
      return {
        ...state,
        add_complaint_loading: false
      };
    case GET_COMPLAINT_STATED:
      return {
        ...state,
        complaint: null,
        complaint_loading: true
      };
    case GET_COMPLAINT:
      return {
        ...state,
        complaint: payload
      };
    case GET_COMPLAINT_ENDED:
      return {
        ...state,
        complaint_loading: false
      };
    case EDIT_COMPLAINT_STATED:
      return {
        ...state,
        complaint_message: null,
        edit_complaint_loading: true
      };
    case EDIT_COMPLAINT:
      return {
        ...state,
        complaint_message: payload
      };
    case EDIT_COMPLAINT_ENDED:
      return {
        ...state,
        edit_complaint_loading: false
      };

    default:
      return state;
  }
};
