export const PAGE_TITLE = "Complaints";
export const PAGE_SINGLE_TITLE = "Complaint";
export const LINK_URL = "complaints";
export const inputFields = {
  jobcard: {
    type: "related",
    required: false,
    title: "Job card",
    field: "name",
  },
  customer: {
    type: "related",
    required: true,
    title: "Customer",
    field: "name",
  },
  technician: {
    type: "related",
    required: true,
    title: "Technician",
    field: "name",
  },
  technician_assigned: {
    type: "checkbox",
    required: true,
    title: "is Technician Assigned?",
  },
  vendor: {
    type: "related",
    required: false,
    title: "Vendor",
    field: "name",
  },
  status: {
    type: "select",
    required: false,
    title: "Status",
    options: ["OPENED", "TECHNICIAN ASSIGNED", "IN PROGRESS", "CLOSED"],
  },
  message: {
    type: "text",
    required: false,
    title: "Message",
  },
};
export const initialValues = {
  jobcard: "",
  customer: "",
  vendor: "",
  status: "",
  message: "",
  technician: "",
  technician_assigned: "",
};

export const view_all_table = [
  { name: "Job Card", related: true, field: "jobcard_id", image: false },
  {
    name: "Customer",
    value: "customer",
    related: true,
    field: "name",
    image: false,
  },
  {
    name: "Vendor",
    value: "vendor",
    related: true,
    field: "name",
    image: false,
  },
  {
    name: "Technician",
    value: "technician",
    related: true,
    field: "name",
    image: false,
  },
  {
    name: "Status",
    value: "status",
  },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "status",
    field: "status",
    label: "Status",
    type: "select",
    options: ["OPENED", "TECHNICIAN ASSIGNED", "IN PROGRESS", "CLOSED"],
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "jobcard",
    field: "jobcard",
    label: "Job Card",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "customer",
    field: "customer",
    label: "Customer",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "technician",
    field: "technician",
    label: "Technician",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "vendor",
    field: "vendor",
    label: "Vendor",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
