import React, { useEffect } from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/app.min.css";
import "./assets/css/custom.css";
import "./assets/css/wysiwyg.css";
import "./assets/css/responsive.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import Login from "./containers/login/Login";
import { loadUser } from "./store/actions/auth";
import BeforeLoginRoutes from "./shared/Routes/BeforeLoginRoutes";
import PrivateRoutes from "./shared/Routes/PrivateRoutes";
import Dashboard from "./containers/dashboard/Dashboard";
import PageNotFound from "./containers/notfound/PageNotFound";

import Profile from "./containers/profile/Profile";
import AddBanner from "./containers/banners/AddBanner";
import AllBanners from "./containers/banners/AllBanners";
import ViewBanner from "./containers/banners/ViewBanner";
import EditBanner from "./containers/banners/EditBanner";

import AddOffer from "./containers/offers/AddOffer";
import AllOffers from "./containers/offers/AllOffers";
import ViewOffer from "./containers/offers/ViewOffer";
import EditOffer from "./containers/offers/EditOffer";

import AddFooter from "./containers/footers/AddFooter";
import AllFooters from "./containers/footers/AllFooters";
import ViewFooter from "./containers/footers/ViewFooter";
import EditFooter from "./containers/footers/EditFooter";

import AddCustomer from "./containers/customers/AddCustomer";
import AllCustomers from "./containers/customers/AllCustomers";
import ViewCustomer from "./containers/customers/ViewCustomer";
import EditCustomer from "./containers/customers/EditCustomer";

import AddVendor from "./containers/vendors/AddVendor";
import AllVendors from "./containers/vendors/AllVendors";
import ViewVendor from "./containers/vendors/ViewVendor";
import EditVendor from "./containers/vendors/EditVendor";

import AddTechnician from "./containers/technicians/AddTechnician";
import AllTechnicians from "./containers/technicians/AllTechnicians";
import ViewTechnician from "./containers/technicians/ViewTechnician";
import EditTechnician from "./containers/technicians/EditTechnician";

import AddOrder from "./containers/orders/AddOrder";
import AllOrders from "./containers/orders/AllOrders";
import ViewOrder from "./containers/orders/ViewOrder";
import EditOrder from "./containers/orders/EditOrder";

import AddCoupon from "./containers/coupons/AddCoupon";
import AllCoupons from "./containers/coupons/AllCoupons";
import ViewCoupon from "./containers/coupons/ViewCoupon";
import EditCoupon from "./containers/coupons/EditCoupon";

import AddProduct from "./containers/products/AddProduct";
import AllProducts from "./containers/products/AllProducts";
import ViewProduct from "./containers/products/ViewProduct";
import EditProduct from "./containers/products/EditProduct";

import AddService from "./containers/services/AddService";
import AllServices from "./containers/services/AllServices";
import ViewService from "./containers/services/ViewService";
import EditService from "./containers/services/EditService";

import AddGenerator from "./containers/generators/AddGenerator";
import AllGenerators from "./containers/generators/AllGenerators";
import ViewGenerator from "./containers/generators/ViewGenerator";
import EditGenerator from "./containers/generators/EditGenerator";

import AddCategory from "./containers/categorys/AddCategory";
import AllCategorys from "./containers/categorys/AllCategorys";
import ViewCategory from "./containers/categorys/ViewCategory";
import EditCategory from "./containers/categorys/EditCategory";

import AddJobcard from "./containers/jobcards/AddJobcard";
import AllJobcards from "./containers/jobcards/AllJobcards";
import ViewJobcard from "./containers/jobcards/ViewJobcard";
import EditJobcard from "./containers/jobcards/EditJobcard";

import AddComplaint from "./containers/complaints/AddComplaint";
import AllComplaints from "./containers/complaints/AllComplaints";
import ViewComplaint from "./containers/complaints/ViewComplaint";
import EditComplaint from "./containers/complaints/EditComplaint";

import AddContact from "./containers/contacts/AddContact";
import AllContacts from "./containers/contacts/AllContacts";
import ViewContact from "./containers/contacts/ViewContact";
import EditContact from "./containers/contacts/EditContact";

import setAuthToken from "./domain/setAuthToken";

function App() {
  useEffect(() => {
    const token = localStorage.getItem("token");
    setAuthToken(token);
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path="/" component={Login} />

          <PrivateRoutes exact path="/dashboard" component={Dashboard} />
          <PrivateRoutes exact path="/profile" component={Profile} />

          <PrivateRoutes exact path="/offers" component={AllOffers} />
          <PrivateRoutes exact path="/offers/add" component={AddOffer} />
          <PrivateRoutes exact path="/offers/:id/view" component={ViewOffer} />
          <PrivateRoutes exact path="/offers/:id/edit" component={EditOffer} />

          <PrivateRoutes exact path="/banners" component={AllBanners} />
          <PrivateRoutes exact path="/banners/add" component={AddBanner} />
          <PrivateRoutes
            exact
            path="/banners/:id/view"
            component={ViewBanner}
          />
          <PrivateRoutes
            exact
            path="/banners/:id/edit"
            component={EditBanner}
          />

          <PrivateRoutes exact path="/footers" component={AllFooters} />
          <PrivateRoutes exact path="/footers/add" component={AddFooter} />
          <PrivateRoutes
            exact
            path="/footers/:id/view"
            component={ViewFooter}
          />
          <PrivateRoutes
            exact
            path="/footers/:id/edit"
            component={EditFooter}
          />
          <PrivateRoutes exact path="/vendors" component={AllVendors} />
          <PrivateRoutes exact path="/vendors/add" component={AddVendor} />
          <PrivateRoutes
            exact
            path="/vendors/:id/view"
            component={ViewVendor}
          />
          <PrivateRoutes
            exact
            path="/vendors/:id/edit"
            component={EditVendor}
          />

          <PrivateRoutes exact path="/customers" component={AllCustomers} />
          <PrivateRoutes exact path="/customers/add" component={AddCustomer} />
          <PrivateRoutes
            exact
            path="/customers/:id/view"
            component={ViewCustomer}
          />
          <PrivateRoutes
            exact
            path="/customers/:id/edit"
            component={EditCustomer}
          />
          <PrivateRoutes exact path="/technicians" component={AllTechnicians} />
          <PrivateRoutes
            exact
            path="/technicians/add"
            component={AddTechnician}
          />
          <PrivateRoutes
            exact
            path="/technicians/:id/view"
            component={ViewTechnician}
          />
          <PrivateRoutes
            exact
            path="/technicians/:id/edit"
            component={EditTechnician}
          />
          <PrivateRoutes exact path="/orders" component={AllOrders} />
          <PrivateRoutes exact path="/orders/add" component={AddOrder} />
          <PrivateRoutes exact path="/orders/:id/view" component={ViewOrder} />
          <PrivateRoutes exact path="/orders/:id/edit" component={EditOrder} />

          <PrivateRoutes exact path="/coupons" component={AllCoupons} />
          <PrivateRoutes exact path="/coupons/add" component={AddCoupon} />
          <PrivateRoutes
            exact
            path="/coupons/:id/view"
            component={ViewCoupon}
          />
          <PrivateRoutes
            exact
            path="/coupons/:id/edit"
            component={EditCoupon}
          />
          <PrivateRoutes exact path="/generators" component={AllGenerators} />
          <PrivateRoutes
            exact
            path="/generators/add"
            component={AddGenerator}
          />
          <PrivateRoutes
            exact
            path="/generators/:id/view"
            component={ViewGenerator}
          />
          <PrivateRoutes
            exact
            path="/generators/:id/edit"
            component={EditGenerator}
          />
          <PrivateRoutes exact path="/categories" component={AllCategorys} />
          <PrivateRoutes exact path="/categories/add" component={AddCategory} />
          <PrivateRoutes
            exact
            path="/categories/:id/view"
            component={ViewCategory}
          />
          <PrivateRoutes
            exact
            path="/categories/:id/edit"
            component={EditCategory}
          />

          <PrivateRoutes exact path="/products" component={AllProducts} />
          <PrivateRoutes exact path="/products/add" component={AddProduct} />
          <PrivateRoutes
            exact
            path="/products/:id/view"
            component={ViewProduct}
          />
          <PrivateRoutes
            exact
            path="/products/:id/edit"
            component={EditProduct}
          />
          <PrivateRoutes exact path="/services" component={AllServices} />
          <PrivateRoutes exact path="/services/add" component={AddService} />
          <PrivateRoutes
            exact
            path="/services/:id/view"
            component={ViewService}
          />
          <PrivateRoutes
            exact
            path="/services/:id/edit"
            component={EditService}
          />
          <PrivateRoutes exact path="/jobcards" component={AllJobcards} />
          <PrivateRoutes exact path="/jobcards/add" component={AddJobcard} />
          <PrivateRoutes
            exact
            path="/jobcards/:id/view"
            component={ViewJobcard}
          />
          <PrivateRoutes
            exact
            path="/jobcards/:id/edit"
            component={EditJobcard}
          />

          <PrivateRoutes exact path="/complaints" component={AllComplaints} />
          <PrivateRoutes
            exact
            path="/complaints/add"
            component={AddComplaint}
          />
          <PrivateRoutes
            exact
            path="/complaints/:id/view"
            component={ViewComplaint}
          />
          <PrivateRoutes
            exact
            path="/complaints/:id/edit"
            component={EditComplaint}
          />

          <PrivateRoutes exact path="/contacts" component={AllContacts} />
          <PrivateRoutes exact path="/contacts/add" component={AddContact} />
          <PrivateRoutes
            exact
            path="/contacts/:id/view"
            component={ViewContact}
          />
          <PrivateRoutes
            exact
            path="/contacts/:id/edit"
            component={EditContact}
          />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
