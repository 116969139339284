export const GET_GENERATORS_STATED = "GET_GENERATORS_STATED";
export const GET_GENERATORS = "GET_GENERATORS";
export const GET_GENERATORS_ENDED = "GET_GENERATORS_ENDED";
export const ADD_GENERATOR_STATED = "ADD_GENERATOR_STARTED";
export const ADD_GENERATOR = "ADD_GENERATOR";
export const ADD_GENERATOR_ENDED = "ADD_GENERATOR_ENDED";
export const EDIT_GENERATOR_STATED = "EDIT_GENERATOR_STATED";
export const EDIT_GENERATOR = "EDIT_GENERATOR";
export const EDIT_GENERATOR_ENDED = "EDIT_GENERATOR_ENDED";
export const GET_GENERATOR = "GET_GENERATOR";
export const GET_GENERATOR_STATED = "GET_GENERATOR_STATED";
export const GET_GENERATOR_ENDED = "GET_GENERATOR_ENDED";
export const RESET_GENERATOR = "RESET_GENERATOR";
export const ERROR_GENERATOR = "ERROR_GENERATOR";
export const GET_ALL_GENERATORS_STATED = "GET_ALL_GENERATORS_STATED";
export const GET_ALL_GENERATORS = "GET_ALL_GENERATORS";
export const GET_ALL_GENERATORS_ENDED = "GET_ALL_GENERATORS_ENDED";
