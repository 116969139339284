export const PAGE_TITLE = "Categorys";
export const PAGE_SINGLE_TITLE = "Category";
export const LINK_URL = "categories";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
    options: "",
    field: "",
  },

  image: {
    type: "file",
    required: false,
    title: "Image",
    inputType: "",
    options: "",
    field: "",
  },
};
export const initialValues = {
  name: "",
};

export const view_all_table = [
  {
    name: "Name",
    value: "name",
    related: "",
    field: "",
    image: false,
  },

  {
    name: "Image",
    value: "image",
    related: "",
    field: "",
    image: true,
  },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
