import moment from "moment";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DoughnutChart from "../../components/charts/DonughtChart";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useGetDashboard } from "../../shared/hooks/UseDashboard";
import { useAllJobcardsByCondition } from "../../shared/hooks/UseJobcard";

const Dashboard = () => {
  const [dashboard_data] = useGetDashboard();
  const [data, setCondition] = useAllJobcardsByCondition();
  const { jobcards_loading, jobcards, total_jobcards, page, pages } = data;
  const { dashboard, dashboard_loading } = dashboard_data;
  useEffect(() => {
    setCondition([{ label: "status", value: "OPENED" }]);
  }, []);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Dashboard"
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink="Main"
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-3">
                  <div className="dashboard-stat pending">
                    <div className="report-title">Total </div>
                    <div className="report-stat">
                      {dashboard && dashboard.total ? dashboard.total : 0}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-stat closed">
                    <div className="report-title">Opened </div>
                    <div className="report-stat">
                      {dashboard && dashboard.opened ? dashboard.opened : 0}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-stat todays">
                    <div className="report-title">Estimate Rejected </div>
                    <div className="report-stat">
                      {dashboard && dashboard.rejected ? dashboard.rejected : 0}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-stat processing">
                    <div className="report-title">Closed Job </div>
                    <div className="report-stat">
                      {" "}
                      {dashboard && dashboard.closed
                        ? dashboard.closed
                        : 0}{" "}
                    </div>
                  </div>
                </div>
                {dashboard &&
                  dashboard.total_amount &&
                  dashboard.total_amount.map((item) => {
                    return (
                      <div className="col-md-3">
                        <div className="dashboard-stat processing">
                          <div className="report-title">
                            {" "}
                            {!item._id
                              ? "Amount to Collect"
                              : "Amount Collected"}{" "}
                          </div>
                          <div className="report-stat"> ₹{item.amount} </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">Upcoming Updates</div>
                    </div>
                    <div className="card-body">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Customer</th>
                            <th> Technician</th>
                            <th> Booking Date </th>
                            <th> Closing Date </th>

                            <th> Total </th>
                            <th> Status </th>
                            <th> Estimate Status </th>
                            <th> Is Paid </th>
                            <th> Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobcards &&
                            jobcards.map((item, index) => {
                              return (
                                <tr>
                                  <td>{item.jobcard_id}</td>
                                  <td>{item.customer && item.customer.name}</td>
                                  <td>
                                    {item.technician && item.technician.name
                                      ? item.technician.name
                                      : "-"}
                                  </td>
                                  <td>
                                    {item.booking_date
                                      ? moment(item.booking_date).format(
                                          "DD-MM-YYYY"
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {item.jobcard_close_date
                                      ? moment(item.jobcard_close_date).format(
                                          "DD-MM-YYYY"
                                        )
                                      : "-"}
                                  </td>

                                  <td> ₹{item.total} </td>
                                  <td> {item.status} </td>
                                  <td> {item.estimate_status} </td>
                                  <td> {item.is_paid ? "YES" : "NO"} </td>
                                  <td>
                                    <Link to={`/jobcards/${item._id}/view`}>
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dashboard-stat">
                <DoughnutChart stats={dashboard && dashboard.stats} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
