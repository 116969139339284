import api from "../../domain/api";
import {
  GET_GENERATORS_STATED,
  GET_GENERATORS,
  GET_GENERATORS_ENDED,
  ADD_GENERATOR_STATED,
  ADD_GENERATOR,
  ADD_GENERATOR_ENDED,
  EDIT_GENERATOR_STATED,
  EDIT_GENERATOR,
  EDIT_GENERATOR_ENDED,
  GET_GENERATOR_STATED,
  GET_GENERATOR,
  GET_GENERATOR_ENDED,
  GET_ALL_GENERATORS_STATED,
  GET_ALL_GENERATORS,
  GET_ALL_GENERATORS_ENDED,
} from "../types/generator_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addGenerator = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_GENERATOR_STATED,
    });
    const { data } = await api.post(`/generators`, formData);
    dispatch({
      type: ADD_GENERATOR,
      payload: data,
    });
    dispatch({
      type: ADD_GENERATOR_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_GENERATOR_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getGenerators =
  ({ pageNumber = "", start_from = "", start_to = "", queryParams }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_GENERATORS_STATED,
      });

      const { data } = await api.get(
        `/generators?&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}${queryParams}`
      );

      dispatch({
        type: GET_GENERATORS,
        payload: data,
      });
      dispatch({
        type: GET_GENERATORS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_GENERATORS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getGenerator = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GENERATOR_STATED,
    });
    const { data } = await api.get(`/generators/${id}`);

    dispatch({
      type: GET_GENERATOR,
      payload: data,
    });
    dispatch({
      type: GET_GENERATOR_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_GENERATOR_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editGenerator = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_GENERATOR_STATED,
    });
    const { data } = await api.put(`/generators/${id}`, formData);
    dispatch({
      type: EDIT_GENERATOR,
      payload: data,
    });
    dispatch({
      type: EDIT_GENERATOR_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_GENERATOR_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteGenerator = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/generators/${id}`);
    dispatch(setAlert("Generator Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllGenerators = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_GENERATORS_STATED,
    });
    const { data } = await api.get(`/generators/all`);

    dispatch({
      type: GET_ALL_GENERATORS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_GENERATORS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_GENERATORS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
