export const GET_COMPLAINTS_STATED = "GET_COMPLAINTS_STATED";
export const GET_COMPLAINTS = "GET_COMPLAINTS";
export const GET_COMPLAINTS_ENDED = "GET_COMPLAINTS_ENDED";
export const ADD_COMPLAINT_STATED = "ADD_COMPLAINT_STARTED";
export const ADD_COMPLAINT = "ADD_COMPLAINT";
export const ADD_COMPLAINT_ENDED = "ADD_COMPLAINT_ENDED";
export const EDIT_COMPLAINT_STATED = "EDIT_COMPLAINT_STATED";
export const EDIT_COMPLAINT = "EDIT_COMPLAINT";
export const EDIT_COMPLAINT_ENDED = "EDIT_COMPLAINT_ENDED";
export const GET_COMPLAINT = "GET_COMPLAINT";
export const GET_COMPLAINT_STATED = "GET_COMPLAINT_STATED";
export const GET_COMPLAINT_ENDED = "GET_COMPLAINT_ENDED";
export const RESET_COMPLAINT = "RESET_COMPLAINT";
export const ERROR_COMPLAINT = "ERROR_COMPLAINT";
export const GET_ALL_COMPLAINTS_STATED = "GET_ALL_COMPLAINTS_STATED";
export const GET_ALL_COMPLAINTS = "GET_ALL_COMPLAINTS";
export const GET_ALL_COMPLAINTS_ENDED = "GET_ALL_COMPLAINTS_ENDED";
