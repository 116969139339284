import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL
} from "../../shared/enums/generators_enum";
import { useUpdateGenerator, useSingleGenerator } from "../../shared/hooks/UseGenerator";
// import { useSelectAllGenerator } from "../../shared/hooks/UseGenerator";

const EditGenerator = ({ match }) => {
  let history = useHistory();
  const [single_data] = useSingleGenerator(match.params.id);
  const [updateData] = useUpdateGenerator();
  const { generator_loading, generator, edit_generator_loading } = single_data;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  //  const [generator1] = useSelectAllGenerator();

  const [dropdownOptions, setDropdownOptions] = useState(null);
  const submitFormClicked = async values => {
    const data = await convertToFormData({ values, featuredImage });
    await updateData(generator._id, data);
    history.push(`/${LINK_URL}/${generator._id}/view`);
  };

  // useEffect(() => {
  //   if (generator1 && generator1.all_generators) {
  //     const newData = generator1.all_generators.map((item) => {
  //       return { label: item.generator_name, value: item._id };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, parent_generator: newData });
  //   }
  // }, [generator1]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!generator_loading ? (
              generator && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {generator[Object.keys(inputFields)[0]]} -{" "}
                        <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${generator._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={generator}
                    edit={true}
                    featuredImage={featuredImage}
                    gallery={gallery}
                    setFeaturedImage={setFeaturedImage}
                    setGallery={setGallery}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                    loading={edit_generator_loading}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGenerator;
