import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/orders_enum";
import { useCreateOrder } from "../../shared/hooks/UseOrder";
import { useSelectAllCustomer } from "../../shared/hooks/UseCustomer";
import { useSelectAllTechnician } from "../../shared/hooks/UseTechnician";
import { useSelectAllVendor } from "../../shared/hooks/UseVendor";
import { useSelectAllCoupon } from "../../shared/hooks/UseCoupon";
import { useSelectAllProduct } from "../../shared/hooks/UseProduct";

const AddOrder = ({}) => {
  let history = useHistory();
  const [order, addData] = useCreateOrder();
  const { add_order_loading } = order;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [customers] = useSelectAllCustomer();
  const [vendors] = useSelectAllVendor();
  const [coupons] = useSelectAllCoupon();
  const [products] = useSelectAllProduct();
  const [technician] = useSelectAllTechnician();

  const [dropdownOptions, setDropdownOptions] = useState({});

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/${LINK_URL}`);
  };

  useEffect(() => {
    if (customers && customers.all_customers) {
      const newData = customers.all_customers.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, customer: newData });
    }
  }, [customers]);
  useEffect(() => {
    if (vendors && vendors.all_vendors) {
      const newData = vendors.all_vendors.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, vendor: newData });
    }
  }, [vendors]);
  useEffect(() => {
    if (coupons && coupons.all_coupons) {
      const newData = coupons.all_coupons.map((item) => {
        return { label: item.code, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, coupon: newData });
    }
  }, [coupons]);
  useEffect(() => {
    if (products && products.all_products) {
      const newData = products.all_products.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, products: newData });
    }
  }, [products]);
  useEffect(() => {
    if (technician && technician.all_technicians) {
      const newData = technician.all_technicians.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, technician: newData });
    }
  }, [technician]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
              loading={add_order_loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrder;
