import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/complaints_enum";
import {
  useUpdateComplaint,
  useSingleComplaint,
} from "../../shared/hooks/UseComplaint";
// import { useSelectAllComplaint } from "../../shared/hooks/UseComplaint";
import { useSelectAllCustomer } from "../../shared/hooks/UseCustomer";
import { useSelectAllTechnician } from "../../shared/hooks/UseTechnician";
import { useSelectAllVendor } from "../../shared/hooks/UseVendor";
import { useSelectAllJobcard } from "../../shared/hooks/UseJobcard";
const EditComplaint = ({ match }) => {
  let history = useHistory();
  const [single_data] = useSingleComplaint(match.params.id);
  const [updateData] = useUpdateComplaint();
  const { complaint_loading, complaint, edit_complaint_loading } = single_data;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [customers] = useSelectAllCustomer();
  const [technicians] = useSelectAllTechnician();
  const [vendors] = useSelectAllVendor();
  const [jobcards] = useSelectAllJobcard();

  const [dropdownOptions, setDropdownOptions] = useState(null);
  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await updateData(complaint._id, data);
    history.push(`/${LINK_URL}/${complaint._id}/view`);
  };

  useEffect(() => {
    if (customers && customers.all_customers) {
      const newData = customers.all_customers.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, customer: newData });
    }
  }, [customers]);
  useEffect(() => {
    if (jobcards && jobcards.all_jobcards) {
      const newData = jobcards.all_jobcards.map((item) => {
        return { label: item.jobcard_id, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, jobcard: newData });
    }
  }, [jobcards]);
  useEffect(() => {
    if (technicians && technicians.all_technicians) {
      const newData = technicians.all_technicians.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, technician: newData });
    }
  }, [technicians]);
  useEffect(() => {
    if (vendors && vendors.all_vendors) {
      const newData = vendors.all_vendors.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, vendor: newData });
    }
  }, [vendors]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!complaint_loading ? (
              complaint && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {complaint[Object.keys(inputFields)[0]]} -{" "}
                        <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${complaint._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={complaint}
                    edit={true}
                    featuredImage={featuredImage}
                    gallery={gallery}
                    setFeaturedImage={setFeaturedImage}
                    setGallery={setGallery}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                    loading={edit_complaint_loading}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditComplaint;
