export const GET_COUPONS_STATED = "GET_COUPONS_STATED";
export const GET_COUPONS = "GET_COUPONS";
export const GET_COUPONS_ENDED = "GET_COUPONS_ENDED";
export const ADD_COUPON_STATED = "ADD_COUPON_STARTED";
export const ADD_COUPON = "ADD_COUPON";
export const ADD_COUPON_ENDED = "ADD_COUPON_ENDED";
export const EDIT_COUPON_STATED = "EDIT_COUPON_STATED";
export const EDIT_COUPON = "EDIT_COUPON";
export const EDIT_COUPON_ENDED = "EDIT_COUPON_ENDED";
export const GET_COUPON = "GET_COUPON";
export const GET_COUPON_STATED = "GET_COUPON_STATED";
export const GET_COUPON_ENDED = "GET_COUPON_ENDED";
export const RESET_COUPON = "RESET_COUPON";
export const ERROR_COUPON = "ERROR_COUPON";
export const GET_ALL_COUPONS_STATED = "GET_ALL_COUPONS_STATED";
export const GET_ALL_COUPONS = "GET_ALL_COUPONS";
export const GET_ALL_COUPONS_ENDED = "GET_ALL_COUPONS_ENDED";
