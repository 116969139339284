import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/jobcards_enum";
import {
  useUpdateJobcard,
  useSingleJobcard,
} from "../../shared/hooks/UseJobcard";
// import { useSelectAllJobcard } from "../../shared/hooks/UseJobcard";
import { useSelectAllService } from "../../shared/hooks/UseService";
import { useSelectAllProduct } from "../../shared/hooks/UseProduct";
import { useSelectAllCustomer } from "../../shared/hooks/UseCustomer";
import { useSelectAllTechnician } from "../../shared/hooks/UseTechnician";
import { useSelectAllVendor } from "../../shared/hooks/UseVendor";
import { useSelectAllCoupon } from "../../shared/hooks/UseCoupon";
import { useSelectAllGenerator } from "../../shared/hooks/UseGenerator";
const EditJobcard = ({ match }) => {
  let history = useHistory();
  const [single_data] = useSingleJobcard(match.params.id);
  const [updateData] = useUpdateJobcard();
  const { jobcard_loading, jobcard, edit_jobcard_loading } = single_data;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [customers] = useSelectAllCustomer();
  const [technicians] = useSelectAllTechnician();
  const [vendors] = useSelectAllVendor();
  const [coupons] = useSelectAllCoupon();
  const [products] = useSelectAllProduct();
  const [services] = useSelectAllService();
  const [generators] = useSelectAllGenerator();
  //  const [jobcard1] = useSelectAllJobcard();

  const [dropdownOptions, setDropdownOptions] = useState(null);
  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await updateData(jobcard._id, data);
    history.push(`/${LINK_URL}/${jobcard._id}/view`);
  };

  // useEffect(() => {
  //   if (jobcard1 && jobcard1.all_jobcards) {
  //     const newData = jobcard1.all_jobcards.map((item) => {
  //       return { label: item.jobcard_name, value: item._id };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, parent_jobcard: newData });
  //   }
  // }, [jobcard1]);
  useEffect(() => {
    if (customers && customers.all_customers) {
      const newData = customers.all_customers.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, customer: newData });
    }
  }, [customers]);
  useEffect(() => {
    if (vendors && vendors.all_vendors) {
      const newData = vendors.all_vendors.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, vendor: newData });
    }
  }, [vendors]);
  useEffect(() => {
    if (coupons && coupons.all_coupons) {
      const newData = coupons.all_coupons.map((item) => {
        return { label: item.code, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, coupon: newData });
    }
  }, [coupons]);
  useEffect(() => {
    if (products && products.all_products) {
      const newData = products.all_products.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, products: newData });
    }
  }, [products]);
  useEffect(() => {
    if (services && services.all_services) {
      const newData = services.all_services.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, services: newData });
    }
  }, [services]);
  useEffect(() => {
    if (generators && generators.all_generators) {
      const newData = generators.all_generators.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, generator: newData });
    }
  }, [generators]);
  useEffect(() => {
    if (technicians && technicians.all_technicians) {
      const newData = technicians.all_technicians.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, technician: newData });
    }
  }, [technicians]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!jobcard_loading ? (
              jobcard && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${jobcard._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={jobcard}
                    edit={true}
                    featuredImage={featuredImage}
                    gallery={gallery}
                    setFeaturedImage={setFeaturedImage}
                    setGallery={setGallery}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                    loading={edit_jobcard_loading}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditJobcard;
