export const GET_JOBCARDS_STATED = "GET_JOBCARDS_STATED";
export const GET_JOBCARDS = "GET_JOBCARDS";
export const GET_JOBCARDS_ENDED = "GET_JOBCARDS_ENDED";
export const ADD_JOBCARD_STATED = "ADD_JOBCARD_STARTED";
export const ADD_JOBCARD = "ADD_JOBCARD";
export const ADD_JOBCARD_ENDED = "ADD_JOBCARD_ENDED";
export const EDIT_JOBCARD_STATED = "EDIT_JOBCARD_STATED";
export const EDIT_JOBCARD = "EDIT_JOBCARD";
export const EDIT_JOBCARD_ENDED = "EDIT_JOBCARD_ENDED";
export const GET_JOBCARD = "GET_JOBCARD";
export const GET_JOBCARD_STATED = "GET_JOBCARD_STATED";
export const GET_JOBCARD_ENDED = "GET_JOBCARD_ENDED";
export const RESET_JOBCARD = "RESET_JOBCARD";
export const ERROR_JOBCARD = "ERROR_JOBCARD";
export const GET_ALL_JOBCARDS_STATED = "GET_ALL_JOBCARDS_STATED";
export const GET_ALL_JOBCARDS = "GET_ALL_JOBCARDS";
export const GET_ALL_JOBCARDS_ENDED = "GET_ALL_JOBCARDS_ENDED";
