export const PAGE_TITLE = "Customers";
export const PAGE_SINGLE_TITLE = "Customer";
export const LINK_URL = "customers";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
  },
  email: {
    type: "string",
    required: false,
    title: "Email",
    inputType: "email",
  },
  username: {
    type: "string",
    required: true,
    title: "Username",
    inputType: "text",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
    inputType: "tel",
  },
  password: {
    type: "string",
    required: true,
    title: "Password",
    inputType: "password",
    hideOnView: true,
    emptyOnEdit: true,
  },
  address: {
    type: "string",
    required: true,
    title: "Address",
    inputType: "text",
  },
  city: {
    type: "string",
    required: true,
    title: "City",
    inputType: "text",
  },
  state: {
    type: "string",
    required: true,
    title: "State",
    inputType: "text",
  },
  pincode: {
    type: "string",
    required: true,
    title: "Pincode",
    inputType: "text",
  },
};
export const initialValues = {
  name: "",
  email: "",
  username: "",
  phone: "",
  password: "",
};

export const view_all_table = [
  {
    name: "Name",
    value: "name",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Username",
    value: "username",
  },
  {
    name: "Phone",
    value: "phone",
  },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },

  {
    id: "email",
    field: "email",
    label: "Email",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "phone",
    field: "phone",
    label: "Phone",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },

  {
    id: "username",
    field: "username",
    label: "Username",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
