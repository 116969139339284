export const PAGE_TITLE = "Technicians";
export const PAGE_SINGLE_TITLE = "Technician";
export const LINK_URL = "technicians";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
  },
  email: {
    type: "string",
    required: false,
    title: "Email",
    inputType: "email",
  },
  username: {
    type: "string",
    required: true,
    title: "Username",
    inputType: "text",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
    inputType: "tel",
  },
  password: {
    type: "string",
    required: true,
    title: "Password",
    inputType: "password",
    hideOnView: true,
    emptyOnEdit: true,
  },
  vendor: {
    type: "related",
    required: false,
    title: "Vendor",
    field: "name",
  },
  address: {
    type: "string",
    required: true,
    title: "Address",
    inputType: "text",
  },
  city: {
    type: "string",
    required: true,
    title: "City",
    inputType: "text",
  },
  state: {
    type: "string",
    required: true,
    title: "State",
    inputType: "text",
  },
  pincode: {
    type: "string",
    required: true,
    title: "Pincode",
    inputType: "text",
  },
};
export const initialValues = {
  name: "",
  email: "",
  username: "",
  phone: "",
  password: "",
  address: "",
};

export const view_all_table = [
  {
    name: "Name",
    value: "name",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Username",
    value: "username",
  },
  {
    name: "Phone",
    value: "phone",
  },

  {
    name: "Address",
    value: "address",
  },
];

export const SIDEBAR_OPTIONS = [
  {
    field: "name",
    label: "Name",
    type: "string",
  },
  {
    field: "email",
    label: "Email",
    type: "string",
  },
  {
    field: "username",
    label: "Username",
    type: "string",
  },
  {
    field: "phone",
    label: "Phone",
    type: "string",
  },

  {
    field: "address",
    label: "Address",
    type: "string",
  },
];
