export const PAGE_TITLE = "Jobcards";
export const PAGE_SINGLE_TITLE = "Jobcard";
export const LINK_URL = "jobcards";
export const inputFields = {
  customer: {
    type: "related",
    required: true,
    title: "Customer",
    inputType: "text",
    options: "",
    field: "name",
    fields: ["name", "phone", "email"],
  },
  technician: {
    type: "related",
    required: false,
    title: "Technician",
    inputType: "text",
    field: "name",
    fields: ["name", "phone", "email"],
  },
  vendor: {
    type: "related",
    required: false,
    title: "Vendor",
    inputType: "text",
    field: "name",
  },
  technician_assigned: {
    type: "checkbox",
    required: false,
    title: "is Technician Assigned",

    options: "",
    field: "",
  },
  description: {
    type: "text",
    required: false,
    title: "Description",
    inputType: "text",
    options: "",
    field: "",
  },
  booking_date: {
    type: "string",
    required: true,
    title: "Booking Date",
    inputType: "date",
    options: "",
    field: "",
  },
  generator: {
    type: "related",
    required: true,
    title: "Generator",
    inputType: "text",
    options: "",
    field: "name",
  },

  address: {
    type: "string",
    required: true,
    title: "Address",
    inputType: "",
    options: "",
    field: "",
  },
  city: {
    type: "string",
    required: false,
    title: "City",
    inputType: "",
    options: "",
    field: "",
  },
  state: {
    type: "string",
    required: false,
    title: "State",
    inputType: "",
    options: "",
    field: "",
  },
  pin: {
    type: "string",
    required: true,
    title: "Pin",
    inputType: "",
    options: "",
    field: "",
  },

  coupon: {
    type: "related",
    required: false,
    title: "Coupon",
    inputType: "",
    options: "",
    field: "code",
  },

  sub_total: {
    type: "string",
    required: false,
    title: "Sub Total",
    inputType: "",
    options: "",
    field: "",
    disabled: true,
  },
  tax: {
    type: "string",
    required: false,
    title: "Tax",
    inputType: "",
    options: "",
    field: "",
    disabled: true,
  },
  discount: {
    type: "string",
    required: false,
    title: "Discount",
    inputType: "",
    options: "",
    field: "",
    disabled: true,
  },
  total: {
    type: "string",
    required: false,
    title: "Total",
    inputType: "",
    options: "",
    field: "",
    disabled: true,
  },
  is_coupon_applied: {
    type: "checkbox",
    required: false,
    title: "Is Coupon Applied",
    inputType: "",
    options: "",
    field: "",
  },
  jobcard_open_date: {
    type: "string",
    required: false,
    title: "Jobcard Open Date",
    inputType: "date",
    options: "",
    field: "",
  },
  jobcard_close_date: {
    type: "string",
    required: false,
    title: "Jobcard Close Date",
    inputType: "date",
    options: "",
    field: "",
  },
  opening_image: {
    type: "file",
    required: false,
    title: "Opening Image",
    inputType: "",
    options: "",
    field: "",
  },
  closing_image: {
    type: "file",
    required: false,
    title: "Closing Image",
    inputType: "",
    options: "",
    field: "",
  },
  status: {
    type: "select",
    required: false,
    title: "Status",
    inputType: "",
    options: [
      "OPENED",
      "TECHNICIAN ASSIGNED",
      "TECHNICIAN IN ROUTE",
      "TECHNICIAN ARRIVED",
      "ESTIMATE SENT",
      "ESTIMATE APPROVED",
      "ESTIMATE REJECTED",
      "IN PROGRESS",
      "CLOSED",
    ],
    field: "",
  },
  estimate_status: {
    type: "select",
    required: false,
    title: "Estimate Status",
    inputType: "",
    options: ["PENDING", "SENT", "APPROVED", "REJECTED"],
    field: "",
  },
  is_paid: {
    type: "checkbox",
    required: false,
    title: "Is Paid?",
    inputType: "",
    options: "",
    field: "",
  },
  is_collected: {
    type: "checkbox",
    required: false,
    title: "Is Collected?",
    inputType: "",
    options: "",
    field: "",
  },

  payment_method: {
    type: "select",
    required: false,
    title: "Payment Method",
    inputType: "",
    options: ["CASH", "UPI", "CARD", "ONLINE"],
    field: "",
  },

  services: {
    type: "related",
    required: false,
    title: "Services",
    inputType: "",
    options: "",
    field: "name",
    multiple: true,
    parent_field: "service",
  },
  products: {
    type: "related",
    required: false,
    title: "Parts",
    inputType: "",
    options: "",
    field: "name",
    multiple: true,
    parent_field: "product",
    hideOnView: true,
  },
  status_check: {
    type: "divider",
    title: "Status Update Type",
    hideOnView: true,
  },
  status_jobcard_open: {
    type: "checkbox",
    required: false,
    title: "Job Card Open",
    inputType: "",
    options: "",
    field: "",
    hideOnView: true,
  },
  status_technician_assigned: {
    type: "checkbox",
    required: false,
    title: "Status Technician Assigned",
    inputType: "",
    options: "",
    field: "",
    hideOnView: true,
  },
  status_start_route: {
    type: "checkbox",
    required: false,
    title: "Status Start Route",
    inputType: "",
    options: "",
    field: "",
    hideOnView: true,
  },
  status_arrived_route: {
    type: "checkbox",
    required: false,
    title: "Status Arrived Route",
    inputType: "",
    options: "",
    field: "",
    hideOnView: true,
  },
  status_estimate_sent: {
    type: "checkbox",
    required: false,
    title: "Status Estimate Sent",
    inputType: "",
    options: "",
    field: "",
    hideOnView: true,
  },
  status_estimate_approved: {
    type: "checkbox",
    required: false,
    title: "Status Estimate Approved",
    inputType: "",
    options: "",
    field: "",
    hideOnView: true,
  },
  status_estimate_rejected: {
    type: "checkbox",
    required: false,
    title: "Status Estimate Rejected",
    inputType: "",
    options: "",
    field: "",
    hideOnView: true,
  },
  status_payment_collected: {
    type: "checkbox",
    required: false,
    title: "Status Payment Collected",
    inputType: "",
    options: "",
    field: "",
    hideOnView: true,
  },
  status_closed_jobcard: {
    type: "checkbox",
    required: false,
    title: "Status Closed Jobcard",
    inputType: "",
    options: "",
    field: "",
    hideOnView: true,
  },
};
export const initialValues = {
  customer: "",
  mechanic: "",
  mechanic_assigned: false,
  description: "",

  serivces: [],
  parts: [],
};

export const view_all_table = [
  {
    name: "Customer",
    value: "customer",
    related: true,
    field: "name",
    image: false,
  },
  {
    name: "Customer Phone",
    value: "customer",
    related: true,
    field: "phone",
    image: false,
  },
  {
    name: "Technician",
    value: "technician",
    related: true,
    field: "name",
    image: false,
  },
  {
    name: "Technician Phone",
    value: "technician",
    related: true,
    field: "phone",
    image: false,
  },
  {
    name: "Booking Date",
    value: "booking_date",
    related: "",
    field: "",
    image: false,
    date: true,
  },
  {
    name: "Closing Date",
    value: "jobcard_close_date",
    related: "",
    field: "",
    image: false,
    date: true,
  },

  {
    name: "Total",
    value: "total",
    related: "",
    field: "",
    image: false,
    preFilled: "₹",
  },
  {
    name: "Status",
    value: "status",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Estimate Status",
    value: "estimate_status",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Is Paid",
    value: "is_paid",
    related: "",
    field: "",
    image: false,
    checkbox: true,
  },
  {
    name: "Is Collected",
    value: "is_paidis_collected",
    related: "",
    field: "",
    image: false,
    checkbox: true,
  },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "booking_date",
    field: "booking_date",
    label: "Booking Date",
    type: "string",
    search_type: "search",
    inputType: "date",
    condition: "",
  },
  {
    id: "status",
    field: "status",
    label: "Status",
    type: "select",
    search_type: "search",
    inputType: "date",
    condition: "",
    options: [
      "OPENED",
      "TECHNICIAN ASSIGNED",
      "TECHNICIAN IN ROUTE",
      "TECHNICIAN ARRIVED",
      "ESTIMATE SENT",
      "ESTIMATE APPROVED",
      "ESTIMATE REJECTED",
      "IN PROGRESS",
      "CLOSED",
    ],
  },

  {
    field: "status",
    label: "Status",
    type: "string",
  },
  {
    field: "estimate_status",
    label: "Estimate Status",
    type: "string",
  },
];
