export const GET_TECHNICIANS_STATED = "GET_TECHNICIANS_STATED";
export const GET_TECHNICIANS = "GET_TECHNICIANS";
export const GET_TECHNICIANS_ENDED = "GET_TECHNICIANS_ENDED";
export const ADD_TECHNICIAN_STATED = "ADD_TECHNICIAN_STARTED";
export const ADD_TECHNICIAN = "ADD_TECHNICIAN";
export const ADD_TECHNICIAN_ENDED = "ADD_TECHNICIAN_ENDED";
export const EDIT_TECHNICIAN_STATED = "EDIT_TECHNICIAN_STATED";
export const EDIT_TECHNICIAN = "EDIT_TECHNICIAN";
export const EDIT_TECHNICIAN_ENDED = "EDIT_TECHNICIAN_ENDED";
export const GET_TECHNICIAN = "GET_TECHNICIAN";
export const GET_TECHNICIAN_STATED = "GET_TECHNICIAN_STATED";
export const GET_TECHNICIAN_ENDED = "GET_TECHNICIAN_ENDED";
export const RESET_TECHNICIAN = "RESET_TECHNICIAN";
export const ERROR_TECHNICIAN = "ERROR_TECHNICIAN";
export const GET_ALL_TECHNICIANS_STATED = "GET_ALL_TECHNICIANS_STATED";
export const GET_ALL_TECHNICIANS = "GET_ALL_TECHNICIANS";
export const GET_ALL_TECHNICIANS_ENDED = "GET_ALL_TECHNICIANS_ENDED";
