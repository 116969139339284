import {
  GET_GENERATORS_STATED,
  GET_GENERATORS,
  GET_GENERATORS_ENDED,
  ADD_GENERATOR_STATED,
  ADD_GENERATOR,
  ADD_GENERATOR_ENDED,
  EDIT_GENERATOR_STATED,
  EDIT_GENERATOR,
  EDIT_GENERATOR_ENDED,
  GET_GENERATOR_STATED,
  GET_GENERATOR,
  GET_GENERATOR_ENDED,
  GET_ALL_GENERATORS_STATED,
  GET_ALL_GENERATORS,
  GET_ALL_GENERATORS_ENDED
} from "../types/generator_type";

const initialState = {
  generators_loading: true,
  generators: null,
  page: null,
  pages: null,
  total_generators: 0,

  generator: null,
  generator_loading: null,

  loading: true,

  generator_message: null,
  all_generators: null,
  all_generators_loading: null,
  add_generator_loading: true,
  edit_generator_loading: true
};

export const generator_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_GENERATORS_STATED:
      return {
        ...state,
        generators: null,
        pages: null,
        page: null,
        total_generators: 0,
        generators_loading: true
      };
    case GET_GENERATORS:
      return {
        ...state,
        generators: payload.generators,
        pages: payload.pages,
        page: payload.page,
        total_generators: payload.count
      };
    case GET_GENERATORS_ENDED:
      return {
        ...state,
        generators_loading: false
      };
    case GET_ALL_GENERATORS_STATED:
      return {
        ...state,
        all_generators_loading: true,
        all_generators: null
      };
    case GET_ALL_GENERATORS:
      return {
        ...state,
        all_generators: payload
      };
    case GET_ALL_GENERATORS_ENDED:
      return {
        ...state,
        all_generators_loading: false
      };

    case ADD_GENERATOR_STATED:
      return {
        ...state,
        generator_message: null,
        add_generator_loading: true
      };
    case ADD_GENERATOR:
      return {
        ...state,
        generator_message: payload
      };
    case ADD_GENERATOR_ENDED:
      return {
        ...state,
        add_generator_loading: false
      };
    case GET_GENERATOR_STATED:
      return {
        ...state,
        generator: null,
        generator_loading: true
      };
    case GET_GENERATOR:
      return {
        ...state,
        generator: payload
      };
    case GET_GENERATOR_ENDED:
      return {
        ...state,
        generator_loading: false
      };
    case EDIT_GENERATOR_STATED:
      return {
        ...state,
        generator_message: null,
        edit_generator_loading: true
      };
    case EDIT_GENERATOR:
      return {
        ...state,
        generator_message: payload
      };
    case EDIT_GENERATOR_ENDED:
      return {
        ...state,
        edit_generator_loading: false
      };

    default:
      return state;
  }
};
