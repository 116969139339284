import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/footers_enum";
import { useUpdateFooter, useSingleFooter } from "../../shared/hooks/UseFooter";
// import { useSelectAllFooter } from "../../shared/hooks/UseFooter";
import { useSelectAllService } from "../../shared/hooks/UseService";
import { useSelectAllProduct } from "../../shared/hooks/UseProduct";
const EditFooter = ({ match }) => {
  let history = useHistory();
  const [single_data] = useSingleFooter(match.params.id);
  const [updateData] = useUpdateFooter();
  const { footer_loading, footer, edit_footer_loading } = single_data;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  //  const [footer1] = useSelectAllFooter();
  const [services] = useSelectAllService();
  const [products] = useSelectAllProduct();
  const [dropdownOptions, setDropdownOptions] = useState(null);
  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await updateData(footer._id, data);
    history.push(`/${LINK_URL}/${footer._id}/view`);
  };

  // useEffect(() => {
  //   if (footer1 && footer1.all_footers) {
  //     const newData = footer1.all_footers.map((item) => {
  //       return { label: item.footer_name, value: item._id };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, parent_footer: newData });
  //   }
  // }, [footer1]);
  useEffect(() => {
    if (services && services.all_services) {
      const newData = services.all_services.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, services: newData });
    }
  }, [services]);
  useEffect(() => {
    if (products && products.all_products) {
      const newData = products.all_products.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, products: newData });
    }
  }, [products]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!footer_loading ? (
              footer && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {footer[Object.keys(inputFields)[0]]} -{" "}
                        <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${footer._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={footer}
                    edit={true}
                    featuredImage={featuredImage}
                    gallery={gallery}
                    setFeaturedImage={setFeaturedImage}
                    setGallery={setGallery}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                    loading={edit_footer_loading}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFooter;
