import api from "../../domain/api";
import {
  GET_DASHBOARD_STATED,
  GET_DASHBOARD_ENDED,
  GET_DASHBOARD,
} from "../types/dashboard_type";
import { handleError } from "../../shared/handleError";

export const getDashboard = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_DASHBOARD_STATED,
    });
    const { data } = await api.get(`/dashboard`);

    dispatch({
      type: GET_DASHBOARD,
      payload: data,
    });
    dispatch({
      type: GET_DASHBOARD_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_DASHBOARD_ENDED,
    });

    dispatch(handleError(error));
  }
};
