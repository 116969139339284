import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/jobcards_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleJobcard } from "../../shared/hooks/UseJobcard";
import { URI } from "../../domain/constant";
const ViewJobcard = ({ match }) => {
  const [data] = useSingleJobcard(match.params.id);
  const { jobcard_loading, jobcard } = data;
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!jobcard_loading ? (
          jobcard && (
            <SingleView
              data={jobcard}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={jobcard._id}
              SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
            >
              {jobcard.products && (
                <>
                  <div className="col-md-12">
                    <div
                      style={{
                        background: "#f1f1f1",
                        padding: "20px 10px",
                        marginBottom: "10px",
                      }}
                    >
                      Products
                    </div>
                  </div>

                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th> # </th>
                        <th> Image </th>
                        <th> Name </th>
                        <th> Quantity </th>
                        <th> Unit Price </th>
                        <th> Sub Total </th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobcard.products &&
                        jobcard.products.map((item, index) => {
                          return (
                            <tr>
                              <td> {index + 1} </td>
                              <td>
                                {" "}
                                {item.product && item.product.image && (
                                  <img
                                    src={`${URI}${item.product.image}`}
                                    style={{ width: "120px" }}
                                  />
                                )}{" "}
                              </td>
                              <td> {item.product && item.product.name} </td>
                              <td>{item.products}</td>
                              <td> {item.product.sale_price} </td>
                              <td>
                                {" "}
                                {item.product &&
                                  item.products * item.product.sale_price}{" "}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </>
              )}
            </SingleView>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewJobcard;
