import React from "react";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

function DoughnutChart({ stats }) {
  console.log(stats);
  const titles = stats
    ? stats.map((item) => {
        return item.title;
      })
    : [];
  const count = stats
    ? stats.map((item) => {
        return item.count;
      })
    : [];

  const data = {
    labels: titles,
    datasets: [
      {
        label: "Sales 2020 (M)",
        data: count,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(255, 205, 86, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(153, 102, 255, 1)",
        ],
      },
    ],
  };

  const options = {
    title: {
      display: true,
      text: "Doughnut Chart",
    },
  };

  return <Doughnut data={data} options={options} />;
}

export default DoughnutChart;
