export const PAGE_TITLE = "Orders";
export const PAGE_SINGLE_TITLE = "Order";
export const LINK_URL = "orders";
export const inputFields = {
  customer: {
    type: "related",
    required: true,
    title: "Customer",
    inputType: "text",
    options: "",
    field: "name",
    fields: ["name", "phone", "email"],
  },
  technician: {
    type: "related",
    required: false,
    title: "Technician",
    inputType: "text",
    field: "name",
    fields: ["name", "phone", "email"],
  },
  vendor: {
    type: "related",
    required: false,
    title: "Vendor",
    inputType: "text",
    field: "name",
    fields: ["name", "phone", "email"],
  },
  address: {
    type: "string",
    required: true,
    title: "Address",
    inputType: "",
    options: "",
    field: "",
  },
  city: {
    type: "string",
    required: false,
    title: "City",
    inputType: "",
    options: "",
    field: "",
  },
  state: {
    type: "string",
    required: false,
    title: "State",
    inputType: "",
    options: "",
    field: "",
  },
  pin: {
    type: "string",
    required: true,
    title: "Pin",
    inputType: "",
    options: "",
    field: "",
  },

  coupon: {
    type: "related",
    required: false,
    title: "Coupon",
    inputType: "",
    options: "",
    field: "code",
  },

  sub_total: {
    type: "string",
    required: false,
    title: "Sub Total",
    inputType: "",
    options: "",
    field: "",
    disabled: true,
  },
  tax: {
    type: "string",
    required: false,
    title: "Tax",
    inputType: "",
    options: "",
    field: "",
    disabled: true,
  },
  discount: {
    type: "string",
    required: false,
    title: "Discount",
    inputType: "",
    options: "",
    field: "",
    disabled: true,
  },
  total: {
    type: "string",
    required: false,
    title: "Total",
    inputType: "",
    options: "",
    field: "",
    disabled: true,
  },
  is_coupon_applied: {
    type: "checkbox",
    required: false,
    title: "Is Coupon Applied",
    inputType: "",
    options: "",
    field: "",
  },
  products: {
    type: "related",
    required: false,
    title: "Parts",
    inputType: "",
    options: "",
    field: "name",
    multiple: true,
    parent_field: "product",
    hideOnView: true,
  },
  is_paid: {
    type: "checkbox",
    required: false,
    title: "Is Paid?",
    inputType: "",
    options: "",
    field: "",
  },
  is_collected: {
    type: "checkbox",
    required: false,
    title: "Is Collected?",
    inputType: "",
    options: "",
    field: "",
  },

  payment_method: {
    type: "select",
    required: false,
    title: "Payment Method",
    inputType: "",
    options: ["CASH", "UPI", "CARD", "ONLINE"],
    field: "",
  },
  status: {
    type: "select",
    required: false,
    title: "Status",
    inputType: "",
    options: ["PLACED", "PROCESSING", "DISPATCHED", "DELIVERED", "CANCELLED"],
    field: "",
  },
};
export const initialValues = {
  customer: "",
  vendor: "",
  address: "",
  city: "",
  pin: "",
  coupon: "",
  sub_total: "",
  tax: "",
  discount: "",
  total: "",
  is_coupon_applied: "",
};

export const view_all_table = [
  { name: "Order ID", value: "order_id" },
  {
    name: "Customer",
    value: "customer",
    related: true,
    field: "name",
    image: false,
  },
  {
    name: "Customer Phone",
    value: "customer",
    related: true,
    field: "phone",
    image: false,
  },
  {
    name: "Technician",
    value: "technician",
    related: true,
    field: "name",
    image: false,
  },
  {
    name: "Technician Phone",
    value: "technician",
    related: true,
    field: "phone",
    image: false,
  },
  {
    name: "Total",
    value: "total",
  },
  {
    name: "Status",
    value: "status",
  },
];

export const SIDEBAR_OPTIONS = [
  {
    field: "order_id",
    label: "Order ID",
    type: "string",
  },
];
