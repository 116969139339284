import api from "../../domain/api";
import {
  GET_OFFERS_STATED,
  GET_OFFERS,
  GET_OFFERS_ENDED,
  ADD_OFFER_STATED,
  ADD_OFFER,
  ADD_OFFER_ENDED,
  EDIT_OFFER_STATED,
  EDIT_OFFER,
  EDIT_OFFER_ENDED,
  GET_OFFER_STATED,
  GET_OFFER,
  GET_OFFER_ENDED,
  GET_ALL_OFFERS_STATED,
  GET_ALL_OFFERS,
  GET_ALL_OFFERS_ENDED,
} from "../types/offer_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addOffer = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_OFFER_STATED,
    });
    const { data } = await api.post(`/offers`, formData);
    dispatch({
      type: ADD_OFFER,
      payload: data,
    });
    dispatch({
      type: ADD_OFFER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_OFFER_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getOffers =
  ({ pageNumber = "", start_from = "", start_to = "", queryParams }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_OFFERS_STATED,
      });

      const { data } = await api.get(
        `/offers?&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}${queryParams}`
      );

      dispatch({
        type: GET_OFFERS,
        payload: data,
      });
      dispatch({
        type: GET_OFFERS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_OFFERS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getOffer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_OFFER_STATED,
    });
    const { data } = await api.get(`/offers/${id}`);

    dispatch({
      type: GET_OFFER,
      payload: data,
    });
    dispatch({
      type: GET_OFFER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_OFFER_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editOffer = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_OFFER_STATED,
    });
    const { data } = await api.put(`/offers/${id}`, formData);
    dispatch({
      type: EDIT_OFFER,
      payload: data,
    });
    dispatch({
      type: EDIT_OFFER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_OFFER_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteOffer = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/offers/${id}`);
    dispatch(setAlert("Offer Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllOffers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_OFFERS_STATED,
    });
    const { data } = await api.get(`/offers/all`);

    dispatch({
      type: GET_ALL_OFFERS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_OFFERS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_OFFERS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
