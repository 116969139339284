import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/complaints_enum";
import { useCreateComplaint } from "../../shared/hooks/UseComplaint";
import { useSelectAllCustomer } from "../../shared/hooks/UseCustomer";
import { useSelectAllTechnician } from "../../shared/hooks/UseTechnician";
import { useSelectAllVendor } from "../../shared/hooks/UseVendor";
import { useSelectAllJobcard } from "../../shared/hooks/UseJobcard";
// import { useSelectAllComplaint } from "../../shared/hooks/UseComplaint";

const AddComplaint = ({}) => {
  let history = useHistory();
  const [complaint, addData] = useCreateComplaint();
  const { add_complaint_loading } = complaint;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [customers] = useSelectAllCustomer();
  const [technicians] = useSelectAllTechnician();
  const [vendors] = useSelectAllVendor();
  const [jobcards] = useSelectAllJobcard();

  const [dropdownOptions, setDropdownOptions] = useState({});

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/${LINK_URL}`);
  };

  useEffect(() => {
    if (customers && customers.all_customers) {
      const newData = customers.all_customers.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, customer: newData });
    }
  }, [customers]);
  useEffect(() => {
    if (jobcards && jobcards.all_jobcards) {
      const newData = jobcards.all_jobcards.map((item) => {
        return { label: item.jobcard_id, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, jobcard: newData });
    }
  }, [jobcards]);
  useEffect(() => {
    if (technicians && technicians.all_technicians) {
      const newData = technicians.all_technicians.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, technician: newData });
    }
  }, [technicians]);
  useEffect(() => {
    if (vendors && vendors.all_vendors) {
      const newData = vendors.all_vendors.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, vendor: newData });
    }
  }, [vendors]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
              loading={add_complaint_loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddComplaint;
