import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/offers_enum";
import { useCreateOffer } from "../../shared/hooks/UseOffer";
// import { useSelectAllOffer } from "../../shared/hooks/UseOffer";
import { useSelectAllService } from "../../shared/hooks/UseService";
import { useSelectAllProduct } from "../../shared/hooks/UseProduct";
const AddOffer = ({}) => {
  let history = useHistory();
  const [offer, addData] = useCreateOffer();
  const { add_offer_loading } = offer;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  // const [offer] = useSelectAllOffer();
  const [services] = useSelectAllService();
  const [products] = useSelectAllProduct();
  const [dropdownOptions, setDropdownOptions] = useState({});

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/${LINK_URL}`);
  };

  // useEffect(() => {
  //   if (offer && offer.all_offers) {
  //     const newData = offer.all_offers.map((item) => {
  //       return { label: item.offer_name, value: item._id };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, parent_offer: newData });
  //   }
  // }, [offer]);
  useEffect(() => {
    if (services && services.all_services) {
      const newData = services.all_services.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, services: newData });
    }
  }, [services]);
  useEffect(() => {
    if (products && products.all_products) {
      const newData = products.all_products.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, products: newData });
    }
  }, [products]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
              loading={add_offer_loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOffer;
