import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/services_enum";
import { useCreateService } from "../../shared/hooks/UseService";
// import { useSelectAllService } from "../../shared/hooks/UseService";
import { useSelectAllCategory } from "../../shared/hooks/UseCategory";
import { useSelectAllGenerator } from "../../shared/hooks/UseGenerator";
const AddService = ({}) => {
  let history = useHistory();
  const [service, addData] = useCreateService();
  const { add_service_loading } = service;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  // const [service] = useSelectAllService();
  const [categories] = useSelectAllCategory();
  const [generators] = useSelectAllGenerator();
  const [dropdownOptions, setDropdownOptions] = useState({});

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/${LINK_URL}`);
  };

  // useEffect(() => {
  //   if (service && service.all_services) {
  //     const newData = service.all_services.map((item) => {
  //       return { label: item.service_name, value: item._id };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, parent_service: newData });
  //   }
  // }, [service]);
  useEffect(() => {
    if (categories && categories.all_categorys) {
      const newData = categories.all_categorys.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, category: newData });
    }
  }, [categories]);
  useEffect(() => {
    if (generators && generators.all_generators) {
      const newData = generators.all_generators.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, generators: newData });
    }
  }, [generators]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
              loading={add_service_loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddService;
