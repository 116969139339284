import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addJobcard,
  getJobcards,
  getJobcard,
  editJobcard,
  deleteJobcard,
  getAllJobcards,
} from "../../store/actions/jobcard_action";
import _debounce from "lodash/debounce";
import { useSelectAllService } from "./UseService";
import { useSelectAllProduct } from "./UseProduct";
import { useSelectAllCustomer } from "./UseCustomer";
import { useSelectAllTechnician } from "./UseTechnician";
import { useSelectAllVendor } from "./UseVendor";
import { useSelectAllCoupon } from "./UseCoupon";
import { useSelectAllGenerator } from "./UseGenerator";

// Get All Data
export const useAllJobcards = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.jobcard);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteJobcard(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getJobcards({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    if (reloadData) {
      allQuery();
      setReloadData(false);
    }
  }, [reloadData]);
  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked, reloadData, setReloadData];
};

// Get All Data
export const useAllJobcardsByCondition = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.jobcard);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  const [condition, setCondition] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteJobcard(deleteEntry));
    }
    if (condition) {
      dispatch(
        getJobcards({
          pageNumber,
          condition,
        })
      );
    }
  }, [deleteEntry, condition, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getJobcards({
          pageNumber,
          condition,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    if (reloadData) {
      allQuery();
      setReloadData(false);
    }
  }, [reloadData]);
  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [
    data,
    setCondition,
    setPageNumber,
    deleteBtnClicked,
    reloadData,
    setReloadData,
  ];
};

// Get Single Data
export const useSingleJobcard = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.jobcard);
  useEffect(() => {
    dispatch(getJobcard(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateJobcard = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.jobcard);
  const addData = async (data) => {
    await dispatch(addJobcard(data));
  };
  return [data, addData];
};
export const useUpdateJobcard = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.jobcard);
  const updateData = async (id, data) => {
    await dispatch(editJobcard(id, data));
  };
  return [updateData];
};

export const useSelectAllJobcard = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.jobcard);
  useEffect(() => {
    dispatch(getAllJobcards());
  }, []);
  return [data];
};

export const useGetDropdownOptions = () => {
  const [dropdownOptions, setDropdownOptions] = useState({});

  const [customers] = useSelectAllCustomer();
  const [technicians] = useSelectAllTechnician();
  const [vendors] = useSelectAllVendor();
  const [coupons] = useSelectAllCoupon();
  const [products] = useSelectAllProduct();
  const [services] = useSelectAllService();
  const [generators] = useSelectAllGenerator();

  useEffect(() => {
    if (customers && customers.all_customers) {
      const newData = customers.all_customers.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, customer: newData });
    }
  }, [customers]);
  useEffect(() => {
    if (vendors && vendors.all_vendors) {
      const newData = vendors.all_vendors.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, vendor: newData });
    }
  }, [vendors]);
  useEffect(() => {
    if (coupons && coupons.all_coupons) {
      const newData = coupons.all_coupons.map((item) => {
        return { label: item.code, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, coupon: newData });
    }
  }, [coupons]);
  useEffect(() => {
    if (products && products.all_products) {
      const newData = products.all_products.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, products: newData });
    }
  }, [products]);
  useEffect(() => {
    if (services && services.all_services) {
      const newData = services.all_services.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, services: newData });
    }
  }, [services]);
  useEffect(() => {
    if (generators && generators.all_generators) {
      const newData = generators.all_generators.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, generator: newData });
    }
  }, [generators]);
  useEffect(() => {
    if (technicians && technicians.all_technicians) {
      const newData = technicians.all_technicians.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, technician: newData });
    }
  }, [technicians]);
  return [
    dropdownOptions,
    // setClientSearchField, setClientSearchValue
  ];
};
