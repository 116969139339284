import { combineReducers } from "redux";
import alert from "./alert_reducer";
import auth from "./auth_reducer";
import { banner_reducer } from "./banner_reducer";
import { category_reducer } from "./category_reducer";
import { complaint_reducer } from "./complaint_reducer";
import { contact_reducer } from "./contact_reducer";
import { coupon_reducer } from "./coupon_reducer";
import { customer_reducer } from "./customer_reducer";
import { dashboard_reducer } from "./dashboard_reducer";
import { footer_reducer } from "./footer_reducer";
import { generator_reducer } from "./generator_reducer";
import { jobcard_reducer } from "./jobcard_reducer";
import { offer_reducer } from "./offer_reducer";
import { order_reducer } from "./order_reducer";
import { product_reducer } from "./product_reducer";
import { service_reducer } from "./service_reducer";
import { technician_reducer } from "./technician_reducer";
import { vendor_reducer } from "./vendor_reducer";

export default combineReducers({
  alert,
  auth,
  banner: banner_reducer,
  category: category_reducer,
  complaint: complaint_reducer,
  contact: contact_reducer,
  coupon: coupon_reducer,
  customer: customer_reducer,
  dashboard: dashboard_reducer,
  footer: footer_reducer,
  generator: generator_reducer,
  jobcard: jobcard_reducer,
  offer: offer_reducer,
  order: order_reducer,
  product: product_reducer,
  service: service_reducer,
  technician: technician_reducer,
  vendor: vendor_reducer,
});
