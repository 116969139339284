import {
  GET_FOOTERS_STATED,
  GET_FOOTERS,
  GET_FOOTERS_ENDED,
  ADD_FOOTER_STATED,
  ADD_FOOTER,
  ADD_FOOTER_ENDED,
  EDIT_FOOTER_STATED,
  EDIT_FOOTER,
  EDIT_FOOTER_ENDED,
  GET_FOOTER_STATED,
  GET_FOOTER,
  GET_FOOTER_ENDED,
  GET_ALL_FOOTERS_STATED,
  GET_ALL_FOOTERS,
  GET_ALL_FOOTERS_ENDED
} from "../types/footer_type";

const initialState = {
  footers_loading: true,
  footers: null,
  page: null,
  pages: null,
  total_footers: 0,

  footer: null,
  footer_loading: null,

  loading: true,

  footer_message: null,
  all_footers: null,
  all_footers_loading: null,
  add_footer_loading: true,
  edit_footer_loading: true
};

export const footer_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_FOOTERS_STATED:
      return {
        ...state,
        footers: null,
        pages: null,
        page: null,
        total_footers: 0,
        footers_loading: true
      };
    case GET_FOOTERS:
      return {
        ...state,
        footers: payload.footers,
        pages: payload.pages,
        page: payload.page,
        total_footers: payload.count
      };
    case GET_FOOTERS_ENDED:
      return {
        ...state,
        footers_loading: false
      };
    case GET_ALL_FOOTERS_STATED:
      return {
        ...state,
        all_footers_loading: true,
        all_footers: null
      };
    case GET_ALL_FOOTERS:
      return {
        ...state,
        all_footers: payload
      };
    case GET_ALL_FOOTERS_ENDED:
      return {
        ...state,
        all_footers_loading: false
      };

    case ADD_FOOTER_STATED:
      return {
        ...state,
        footer_message: null,
        add_footer_loading: true
      };
    case ADD_FOOTER:
      return {
        ...state,
        footer_message: payload
      };
    case ADD_FOOTER_ENDED:
      return {
        ...state,
        add_footer_loading: false
      };
    case GET_FOOTER_STATED:
      return {
        ...state,
        footer: null,
        footer_loading: true
      };
    case GET_FOOTER:
      return {
        ...state,
        footer: payload
      };
    case GET_FOOTER_ENDED:
      return {
        ...state,
        footer_loading: false
      };
    case EDIT_FOOTER_STATED:
      return {
        ...state,
        footer_message: null,
        edit_footer_loading: true
      };
    case EDIT_FOOTER:
      return {
        ...state,
        footer_message: payload
      };
    case EDIT_FOOTER_ENDED:
      return {
        ...state,
        edit_footer_loading: false
      };

    default:
      return state;
  }
};
